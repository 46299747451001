import { deleteCourse, listCourses } from 'API/course'
import { getUserData } from 'API/user'
import AddClassesTable from 'components/admin/AddClassesTable'
import { Link } from 'gatsby'
import React, { Fragment, useEffect } from 'react'
import { useState } from 'react'

import { CourseType } from 'types/CourseAdditionMetaData'
import AddCoursesPage from '../add/courses'
import { PromiseType } from 'utility-types'

export default function Group() {
	const [courses, setCourses] = useState<CourseType[]>([])
	const [userData, setUserData] = React.useState<PromiseType<ReturnType<typeof getUserData>>>()
	const [status, setStatus] = useState('All')
	const [update, setUpdate] = useState(0)
	useEffect(() => {
		const run = async () => {
			const courses = await listCourses()
			if (courses) {
				if (status === 'Archived') {
					setCourses(courses.filter((course) => course.is_archived === true))
				} else if (status === 'Active') {
					setCourses(courses.filter((course) => course.is_archived === false))
				} else {
					setCourses(courses)
				}
			}
		}

		run()
	}, [update, status])

	React.useEffect(() => {
		const run = async () => {
			// runAuth()
			const userData = await getUserData()
			if (userData) {
				setUserData(userData)
			}
		}
		run()
		// async function get_data() {
		// getUserData(await ApiLinks.user_data(localStorage.getItem('uid')))
		// }
		// get_data()
	}, [])

	const columns = [
		// {
		// 	text: 'Id',
		// 	dataField: 'id',
		// 	sort: true,
		// },
		{
			text: 'Course Name',
			dataField: 'name',
			sort: true,
		},
		{
			text: 'Description',
			dataField: 'description',
			sort: true,
		},
		{
			text: 'Institute',
			dataField: 'Institute',
			sort: true,
		},
		{
			text: 'year',
			dataField: 'year',
			sort: true,
		},
		{
			text: 'Program',
			dataField: 'Program',
			sort: true,
		},
		{
			text: 'Semester',
			dataField: 'Semester',
			sort: true,
		},
		{
			text: 'Category',
			dataField: 'course_tag',
			sort: true,
		},
		{
			text: 'Type',
			dataField: 'type_of_course',
			sort: true,
		},
		{
			text: 'Archived',
			dataField: 'is_archived',
			sort: true,
			formatter: (is_archived: boolean) => {
				return <>{is_archived ? 'Archived' : 'Active'}</>
			},
		},
		{
			text: 'View',
			dataField: 'uid',
			sort: true,
			formatter: (e: string) => {
				return (
					<>
						<Link to={`./${e}`}>View</Link>
					</>
				)
			},
		},
	]

	if (userData?.admin === true) {
		columns.push({
			text: 'Delete',
			dataField: 'uid',
			sort: false,
			formatter: (e: any) => {
				return (
					<>
						<div
							onClick={async () => {
								if (window.confirm(`Delete Course with UID ${e}?`)) {
									if (window.confirm(`Are you sure you want to delete Course with UID ${e}??`)) {
										await deleteCourse(e)
										setUpdate((update) => update + 1)
									}
								}
							}}
							className='btn btn-danger'
						>
							Delete Course
						</div>
					</>
				)
			},
		})
	}

	const [selected, setSelected] = useState([])

	return (
		<Fragment>
			<AddCoursesPage />
			<div className='card'>
				<div className='card-header bg-white'></div>
				<select
					name='courseType'
					id='selectList'
					className='card-busernameody col-6'
					onChange={(e) => {
						setStatus(e.target.value)
					}}
				>
					<option value='All'>All</option>
					<option value='Archived'>Archived</option>
					<option value='Active'>Active</option>
				</select>
				<div className='card-busernameody'>
					<AddClassesTable useSelect={[selected, setSelected]} data={courses} columns={columns} />
				</div>
			</div>

			<table hidden className='table'>
				<thead>
					<tr>
						<th scope='col'>#</th>
						<th scope='col'>Course Name</th>
						<th scope='col'>Category</th>
						<th scope='col'>Description</th>
						<th scope='col'>Description</th>
						<th scope='col'>Delete</th>
					</tr>
				</thead>
				<tbody>
					{courses?.map((elem, index) => {
						return (
							<>
								<tr>
									<td scope='row'>{index + 1}</td>
									<Link to={`./${elem.uid}`}>
										<td>{elem.name}</td>
									</Link>
									<td>{elem.course_tag}</td>
									<td>{elem.description}</td>
									<td>
										<div
											onClick={async () => {
												if (window.confirm(`Delete Course with UID ${elem.uid}?`)) {
													if (window.confirm(`Are you sure you want to delete Course with UID ${elem.uid}??`)) {
														await deleteCourse(elem.uid)
														setUpdate((update) => update + 1)
													}
												}
											}}
											className='btn btn-danger'
										>
											Delete Course
										</div>
									</td>
								</tr>
							</>
						)
					})}
				</tbody>
			</table>
		</Fragment>
	)
}
