import { Icon } from '@iconify/react'
import { addUser } from 'API/user'
import { getUserData } from 'API/user'

import { navigate } from 'gatsby'
import React, { useState } from 'react'
import { Row, Col, Button, Form, Modal } from 'react-bootstrap'
import { useAppDispatch } from 'redux/hooks'
import { createSingleCourse } from 'API/course'
import { ApplicationStateActions } from 'redux/slices/ApplicationState'
import { create } from 'filepond'
import { PromiseType } from 'utility-types'

const AddCoursesPage: React.FC = () => {
	const [userData, setUserData] = React.useState<PromiseType<ReturnType<typeof getUserData>>>()
	const [show, setShow] = useState(false)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const [modalData, setModalData] = useState({
		name: '',
		description: '',
		course_tag: '',
		end_date: '',
		type_of_course: 'Traditional',
		start_date: '',
		year: 'I',
		Institute: 'JNMC',
		Program: 'UG',
		Semester: 'I',
	})
	const dispatch = useAppDispatch()

	React.useEffect(() => {
		const run = async () => {
			const userData = await getUserData()
			if (userData) {
				setUserData(userData)
			}
		}
		run()
	}, [])

	return (
		<>
			{userData?.admin && (
				<div className='row mt-4 mx-2'>
					<div className='col-3'>
						<h2>Add Courses</h2>
					</div>
					<div className='col-9 px-2'>
						<div className='float-right'>
							<button onClick={() => window.open('/course_sample_updated.csv')} className='btn btn-primary'>
								<Icon icon='bx:bx-download' className='fa fa-download px-2 '></Icon>
								Download Sample File
							</button>
						</div>
						<div className='float-right px-2'>
							<button className='btn btn-primary' onClick={handleShow}>
								<Icon icon='fa:fa-plus' className='fa fa-plus px-2'></Icon>
								<span className='pl-2'>Add Course</span>
							</button>
						</div>
						<div className='float-right px-2'>
							<button
								className='btn btn-primary'
								onClick={() => {
									navigate('/admin/add/courses/batch')
								}}
							>
								<Icon icon='fa:fa-plus' className='fa fa-plus px-2'></Icon>
								<span className='pl-2'>Batch Upload</span>
							</button>
						</div>
					</div>
				</div>
			)}
			<>
				<Modal show={show} onHide={handleClose} backdrop='static' keyboard={false} size='lg' centered>
					<form
						onSubmit={async (e) => {
							e.preventDefault()
							console.log(modalData)
							// await addUser(modalData)
							// await createSingleCourse(modalData)

							try {
								const result = await createSingleCourse(modalData)
								if (result) {
									console.log(modalData)
									dispatch(ApplicationStateActions.setUpdate())
									handleClose()
									window?.location.reload()
								}
							} catch (error) {
								console.log(error)
							}
						}}
					>
						<Modal.Header closeButton>
							<Modal.Title>Add Course</Modal.Title>
						</Modal.Header>

						<Modal.Body>
							<Row></Row>

							<Row>
								<Col lg={12}>
									<Form.Group controlId='txtCourseName'>
										<Form.Label>Course Name</Form.Label>
										<input
											className='form-control'
											required
											type='text'
											placeholder='Course Name'
											onChange={(e) => {
												setModalData({
													...modalData,
													name: e.target.value,
												})
											}}
										/>
									</Form.Group>
								</Col>

								<Col lg={12}>
									<Form.Group controlId='txtDescription'>
										<Form.Label>Description</Form.Label>
										<input
											className='form-control'
											required
											type='text'
											placeholder='Description'
											onChange={(e) => {
												setModalData({
													...modalData,
													description: e.target.value,
												})
											}}
										/>
									</Form.Group>
								</Col>

								<Col lg={6}>
									<Form.Group controlId='txtEndDate'>
										<Form.Label>Start Date</Form.Label>
										<input
											className='form-control'
											required
											type='datetime-local'
											placeholder='Start Date'
											onChange={(e) => {
												setModalData({
													...modalData,
													start_date: e.target.value,
												})
											}}
										/>
									</Form.Group>
								</Col>

								<Col lg={6}>
									<Form.Group controlId='txtEndDate'>
										<Form.Label>End Date</Form.Label>
										<input
											className='form-control'
											required
											type='datetime-local'
											placeholder='End Date'
											onChange={(e) => {
												setModalData({
													...modalData,
													end_date: e.target.value,
												})
											}}
										/>
									</Form.Group>
								</Col>

								<Col lg={6}>
									<Form.Group controlId='txtCategory'>
										<Form.Label>Course Tag</Form.Label>
										<input
											className='form-control'
											required
											type='text'
											placeholder='Category'
											onChange={(e) => {
												setModalData((modalData) => ({
													...modalData,
													course_tag: e.target.value,
												}))
											}}
										/>
									</Form.Group>
								</Col>

								<Col lg={6}>
									<Form.Group controlId='txtCategory'>
										<Form.Label>Type</Form.Label>
										<select
											name='courseType'
											id='selectList'
											className='form-control'
											onChange={(e) => {
												setModalData((modalData) => ({
													...modalData,
													type_of_course: e.target.value,
												}))
											}}
										>
											<option value='Traditional'>Traditional</option>
											<option value='Online'>Online</option>
										</select>
									</Form.Group>
								</Col>
								<Col lg={6}>
									<Form.Group controlId='txtCategory'>
										<Form.Label>Program</Form.Label>
										<select
											name='courseType'
											id='selectList'
											className='form-control'
											onChange={(e) => {
												setModalData((modalData) => ({
													...modalData,
													Program: e.target.value,
												}))
											}}
										>
											<option value='PG'>PG</option>
											<option value='UG'>UG</option>
										</select>
									</Form.Group>
								</Col>
								<Col lg={6}>
									<Form.Group controlId='txtCategory'>
										<Form.Label>Institute</Form.Label>
										<select
											name='courseType'
											id='selectList'
											className='form-control'
											onChange={(e) => {
												setModalData((modalData) => ({
													...modalData,
													Institute: e.target.value,
												}))
											}}
										>
											<option value='JNMC'>JNMC</option>
											<option value='SPDC'>SPDC</option>
											<option value='MGAC'>MGAC</option>
											<option value='RNPC'>RNPC</option>
											<option value='DMCP'>DMCP</option>
											<option value='DMMC'>DMMC</option>
											<option value='SRMMCON'>SRMMCON</option>
											<option value='FEAT'>FEAT</option>
											<option value='CDOE'>CDOE</option>
											<option value='AHS'>AHS</option>
											<option value='FOCM'>FOCM</option>

											<option value='Others'>Others</option>
										</select>
									</Form.Group>
								</Col>
								<Col lg={6}>
									<Form.Group controlId='txtCategory'>
										<Form.Label>Year</Form.Label>
										<select
											name='courseType'
											id='selectList'
											className='form-control'
											onChange={(e) => {
												setModalData((modalData) => ({
													...modalData,
													year: e.target.value,
												}))
											}}
										>
											<option value='I'>I</option>
											<option value='II'>II</option>
											<option value='III'>III</option>
											<option value='IV'>IV</option>
										</select>
									</Form.Group>
								</Col>
								<Col lg={6}>
									<Form.Group controlId='txtCategory'>
										<Form.Label>Semester</Form.Label>
										<select
											name='courseType'
											id='selectList'
											className='form-control'
											onChange={(e) => {
												setModalData((modalData) => ({
													...modalData,
													Semester: e.target.value,
												}))
											}}
										>
											<option value='I'>I</option>
											<option value='II'>II</option>
											<option value='III'>III</option>
											<option value='IV'>IV</option>
											<option value='V'>V</option>
											<option value='VI'>VI</option>
											<option value='VII'>VII</option>
											<option value='VIII'>VIII</option>
										</select>
									</Form.Group>
								</Col>
							</Row>
						</Modal.Body>

						<Modal.Footer>
							<Button variant='secondary' onClick={handleClose}>
								Close
							</Button>
							<Button type={'submit'} variant='primary'>
								Save changes
							</Button>
						</Modal.Footer>
					</form>
				</Modal>
			</>
		</>
	)
}

export default AddCoursesPage
